import {createStore, createEffect} from "effector";
import {createEvent} from "effector";

export const JsonQuiz = createStore(null);

export const EventGetJson = createEvent("get json");

export const GetJson = createEffect({
  async handler() {
    let linkJSON = document.getElementById('root').getAttribute('quiz-src');
    const res = fetch('https://quiz.joint-studio.ru/api.v1/?link=' + linkJSON).then(response => response.json()).then(data => {return data});

    return res;
  },
});

GetJson.done.watch(({params, result}) => {
  EventGetJson(result);
  console.log('Watch params: ', params) // {name: 'zerobias'}
  console.log('Watch result: ', result) // resolved value
});