
const quizcolor = '#051b35';

const zaiperlink = 'https://hooks.zapier.com/hooks/catch/2604291/o10kmrl/';

//'vk': '',
const counters = {
    'metrika': '0',
    'facebook': '0',
    'analytics': '0',
};

const mainpage = {
    'mainimage': 'https://quiz.joint-studio.ru/image/main.jpg',
    'phone': '+7(999)981-23-59',
    'quiztitle': 'Ответь на несколько вопросов',
    'quizdescription': 'Пройди квиз, посмотри как он работает и открой доступ к 14 дневному тестовому периоду и другим бонусам',
}

const endform = {
    'endimage': 'https://quiz.joint-studio.ru/image/end.jpg',
    'legallink': 'https://joint-studio.ru/privacy-policy/',
};

const questarray = {
    'quest1': {
        'quest': 'Шаг 1 из 5. С какой целью хотите использовать квиз?',
        'answer1': 'Для себя или своего бизнеса',
        'image1': null,
        'answer2': 'Для клиента, я маркетолог',
        'image2': null,
        'answer3': null,
        'image3': null,
        'answer4': null,
        'image4': null,
    },
    'quest2': {
        'quest': 'Шаг 2 из 5. Выберите вашегу отрасль',
        'answer1': 'Торговля (опт или розница) ',
        'image1': null,
        'answer2': 'IT-разработка',
        'image2': null,
        'answer3': 'Маркетинг, реклама',
        'image3': null,
        'answer4': 'Инфобизнес',
        'image4': null,
    },
    'quest3': {
        'quest': 'Шаг 3 из 5. Чего хотите добиться в конечном итоге при помощи квиза?',
        'answer1': 'Повысить лояльность аудитории',
        'image1': null,
        'answer2': 'Нужно как можно больше заявок/лидов/продаж',
        'image2': null,
        'answer3': 'Быстро протестировать нишу или гепотизу',
        'image3': null,
        'answer4': 'Помощь в подборе товара или услуги',
        'image4': null,
    },
    'quest4': {
        'quest': 'Шаг 4 из 5. Ваш опыт создания квизов',
        'answer1': 'Впервые пробую',
        'image1': null,
        'answer2': '1-3 квиза',
        'image2': null,
        'answer3': 'Постоянно создаю квизы',
        'image3': null,
        'answer4': null,
        'image4': null,
    },
    'quest5': {
        'quest': 'Шаг 5 из 5. Что нам стоить улучшить?',
        'answer1': 'Функционал',
        'image1': null,
        'answer2': 'Дизайн UI/UX',
        'image2': null,
        'answer3': 'Интеграции',
        'image3': null,
        'answer4': 'Поддержку клиентов',
        'image4': null,
    },
};

let config;

export default config = {
    mainpage,
    quizcolor,
    zaiperlink,
    counters,
    questarray,
    endform,
};