import React from 'react';
import styled, {ThemeProvider} from 'styled-components';

import { YMInitializer } from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import {QuizHandler} from './components/quiz';
import MainPage from './components/mainpage';

import {createEvent} from "effector";
import {createStore, createEffect} from "effector";

import {JsonQuiz} from "./components/store/";
import {GetJson, EventGetJson} from "./components/store/";
import {createStoreConsumer} from 'effector-react';
const WithJson = createStoreConsumer({JsonQuiz});

JsonQuiz.on(GetJson.doneData, (_, data) => data);

class SwitcherComponents extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectPage: 1,
      currentComponent : 'mainpage',
    }
    this.SwitchHandler = this.SwitchHandler.bind(this);
    this.UpdatePage = this.UpdatePage.bind(this);
  }

  UpdatePage(){
    this.setState({
      selectPage: 2,
    });
  }

  componentDidMount(){
    /*
    ReactPixel.init(cfg.counters.facebook);
    ReactPixel.pageView();
    */
  }

  SwitchHandler(count){
    let mycomponent;

    switch(count){
      case 1:
        mycomponent = (
          <MainPage buttonFunc={this.UpdatePage} />
        );
        break;
      case 2:
        mycomponent = (
          <QuizHandler />
        );
        break;
    }
    return mycomponent;
  }

  render(){
    return (
      <div className="container-fluid">
        {this.SwitchHandler(this.state.selectPage)}
      </div>
    );
  }
}

GetJson();

function App() {
  return (
      <React.Fragment>
        <WithJson>
          {myJson => myJson && myJson.JsonQuiz ? (
            <ThemeProvider theme={ { main: myJson.JsonQuiz.quizcolor } }>
              <SwitcherComponents />
              { /* <YMInitializer accounts={[cfg.counters.metrika]} options={{webvisor: true, trackHash:true, accurateTrackBounce:true, clickmap:true}}/> */  }
            </ThemeProvider>
          ) : null }
        </WithJson> 
      </React.Fragment>
  );
}

export default App;
