import React from 'react';
import ym from 'react-yandex-metrika';
import util from 'util';

import {
    MainQuizBody, QuizBlock, QuizTitle, HR, DLeftCol, AnswerColumn, RowAnswer, AnswerCard, AnswerImage, AnswerText, DRightCol, AssistantRow,
    AssistantLCol, MobileSaleLabel, AssistantImg, AssistantRCol, AssistantTitle, AssistantSubtitle, AssistantMessage, SaleCount,BackQuiest,
} from './answers/stylesheets/';

import { inject } from 'effector-react-inject';
import {createStoreConsumer} from 'effector-react'
import { JsonQuiz } from "./../components/store";
const WithJson = createStoreConsumer({JsonQuiz});

class SaleComponent extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        let SelectComponent;

        let ButtonComponent;
        if(this.props.saleCount >= 2){
            ButtonComponent = (<BackQuiest onClick={this.props.handlerButton}><i class="far fa-arrow-alt-circle-left"></i> Предыдущий вопрос</BackQuiest>);
        }
        else ButtonComponent = null;

        if(this.props.next != 7){
            SelectComponent = (
                <DRightCol>
                    <AssistantRow>
                        <AssistantLCol>
                            <AssistantImg src="https://www.firestock.ru/wp-content/uploads/2015/06/new-manager-700x635.jpg"/>
                        </AssistantLCol>
                        <AssistantRCol>
                            <AssistantTitle>Валентина</AssistantTitle>
                            <AssistantSubtitle>Консультант</AssistantSubtitle>
                        </AssistantRCol>
                        <AssistantMessage>Вы экономите наше время. Поэтому за каждый ответ ваша скидка растет.</AssistantMessage>
                    </AssistantRow>
                    <MobileSaleLabel>За ответы скидка растет</MobileSaleLabel>
                    <SaleCount>Ваша скидка: {this.props.saleCount} %</SaleCount>
                    {ButtonComponent}
                </DRightCol>
            );
        }
        else return null;

        return SelectComponent;
    }
}

export default SaleComponent;