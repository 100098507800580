import React from 'react';
import ReactPixel from 'react-facebook-pixel';

import {
    MainQuizBody, QuizBlock, QuizTitle, HR, DLeftCol, AnswerColumn, RowAnswer, AnswerCard, AnswerImage, AnswerText, DRightCol, AssistantRow,
    AssistantLCol, MobileSaleLabel, AssistantImg, AssistantRCol, AssistantTitle, AssistantSubtitle, AssistantMessage, SaleCount,BackQuiest,
} from './answers/stylesheets/';

import QuestGenerator from './quest-generator';
import SaleComponent from './SaleComponents';

import {EndForm} from './answers/endform';

import cfg from './../cfg/';

export class QuizHandler extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            CurrentQuest: 1,
            saleCount: 0,
            answerArray: {
                quest1: '',
                quest2: '',
                quest3: '',
                quest4: '',
                quest5: '',
                quest6: '',
            },
        };

        this.ChangeQuest = this.ChangeQuest.bind(this);
        this.BackQuiestHandler = this.BackQuiestHandler.bind(this);
    }

    ChangeQuest(myCount){
        
        let answerName = 'quest' + this.state.CurrentQuest;
        if(this.state.CurrentQuest == 6){
            this.setState(state => {
                let old = state.answerArray;
                old[answerName] = myCount;
                return {
                    CurrentQuest: this.state.CurrentQuest + 1,
                    answerArray: old,
                };
            });
        }
        else{
            this.setState(state => {
                let old = state.answerArray;
                old[answerName] = myCount;
                return {
                    CurrentQuest: this.state.CurrentQuest + 1,
                    answerArray: old,
                    saleCount: state.saleCount + 4,
                };
            });
        }
    }

    BackQuiestHandler(){
        this.setState({
            CurrentQuest: this.state.CurrentQuest - 1,
            saleCount: this.state.saleCount - 4,
        });
    }

    render(){
        return (
            <React.Fragment>
                <MainQuizBody>
                    <QuizBlock>
                        {cfg.questarray['quest' + this.state.CurrentQuest] ? 
                        <React.Fragment>
                            <QuestGenerator AllAnswers={this.state.answerArray} QuestNumber={this.state.CurrentQuest} ChangeQuest={this.ChangeQuest} />
                            <SaleComponent QuestNumber={this.state.CurrentQuest} saleCount={this.state.saleCount} handlerButton={this.BackQuiestHandler} />
                        </React.Fragment>
                        : null}
                        {cfg.questarray['quest' + this.state.CurrentQuest] ? null :  <EndForm  saleCount={this.state.saleCount} ChangeQuest={this.ChangeQuest} AllAnswers={this.state.answerArray}/>}
                        
                    </QuizBlock>
                </MainQuizBody>
            </React.Fragment>
        );
    }
}