import React from 'react';

import {
    MainQuizBody,
    QuizBlock,
    QuizTitle,
    FormLabel,
    HR,
    H3,
    H5,
    MainH2,
    DLeftCol,
    DRightCol,
    MainSendButton,
    MainImage,DLeftColMain,
} from './answers/stylesheets/';


import { inject } from 'effector-react-inject';
import { JsonQuiz } from "./../components/store";
import {createStoreConsumer} from 'effector-react';
const WithJson = createStoreConsumer({JsonQuiz});

class MainPage extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return (
            <React.Fragment>
                <WithJson>
                {myJson => myJson && myJson.JsonQuiz ? (
                <MainQuizBody>
                    <QuizBlock>
                        <DLeftColMain>
                            <MainImage src={myJson.JsonQuiz.mainpage.mainimage}/>
                        </DLeftColMain>
                        <DRightCol>
                            <FormLabel>{ myJson.JsonQuiz.mainpage.quiztitle ? myJson.JsonQuiz.mainpage.quiztitle : null }</FormLabel>
                            <br/>
                            <H5>{ myJson.JsonQuiz.mainpage.quizdescription ? myJson.JsonQuiz.mainpage.quizdescription : null }</H5>
                            <MainSendButton style={{ backgroundColor: myJson.JsonQuiz.quizColor ? myJson.JsonQuiz.mainpage.quizdescription : null }} onClick={this.props.buttonFunc}><i class="far fa-check-circle"></i> Пройти тест</MainSendButton>
                            <p>Есть вопрос? Звоните!</p>
                            <p><b>{myJson.JsonQuiz.mainpage.phone ? myJson.JsonQuiz.mainpage.phone : null }</b></p>
                        </DRightCol>
                    </QuizBlock>
                </MainQuizBody>
                ) : null}
                </WithJson>
            </React.Fragment>
        );
    }
}

export default MainPage;