import React from 'react';
import ym from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import cfg from './../../cfg/';

import {
    QuizBlock,
    QuizTitle,
    HR,
    DLeftCol,
    AnswerColumn,
    RowAnswer,DLeftColMain,
    KRowAnswer,RowForm,K100Count,LeftRowForm,
    AnswerCard,
    MainImage,
    AnswerText,
    DRightCol,
    AssistantRow,
    AssistantLCol,
    AssistantImg,
    AssistantRCol,
    AssistantTitle,
    AssistantSubtitle,
    AssistantMessage,
    FormBigImg,
    FormAgreement,
    FormLabel,
    SaleCount,
    QuizInput,
    SendButton,
    LegalLink,
} from './stylesheets/';

import {createStoreConsumer} from 'effector-react'
import { JsonQuiz } from "./../../components/store";
const WithJson = createStoreConsumer({JsonQuiz});

export class EndForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            phone: '',
            СonditionSent: true,
        };

        this.InputHandler = this.InputHandler.bind(this);
        this.SubmitHandler = this.SubmitHandler.bind(this);
    }

    GetCookie(name) {
        let matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : '';
    }

    SubmitHandler(e){
        e.preventDefault();
        
        let roistat = this.GetCookie('roistat_visit');

        let QuizData = {
            Name: this.state.name,
            Phone: this.state.phone,
            Answer: this.props.AllAnswers,
            Roistat: roistat,
        };
        let response = fetch(JsonQuiz.getState().zaiperlink, {
            method: 'post',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(QuizData)
        }).then(response => response.json());

        this.setState({
            СonditionSent: false,
        });
        /*
        ReactPixel.track('Purchase', {currency: "USD", value: 1.00} );
        ReactPixel.track('Lead', {content_name: this.state.name, content_category: 'quiz'} );
        ReactGA.event({
            category: 'quiz',
            action: 'sendform',
            label: 'sendform'
        });
        */
        //ReactGA.ga('send', 'quiz', 'sendform');
        //ym('reachGoal', 'send');
    }

    InputHandler(e){
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    componentDidMount() {
        //ReactGA.initialize(cfg.counters.analytics);
        //ReactPixel.init(cfg.counters.facebook);
    }

    render(){
        let FormHandler;

        if(this.state.СonditionSent){
            FormHandler = (
                <RowForm>
                    <WithJson>
                        {myJson => myJson && myJson.JsonQuiz ? (
                        <K100Count>
                            <FormLabel>Итоговая скидка:</FormLabel>
                            <SaleCount>{this.props.saleCount}%</SaleCount>
                            <FormAgreement>Заполните контактные данные для получения скидки.</FormAgreement>
                            <form onSubmit={this.SubmitHandler}>
                                <FormLabel>Ваше имя:</FormLabel>
                                <QuizInput name="name" onChange={this.InputHandler} required/>
                                
                                <FormLabel>Ваш телефон:</FormLabel>
                                <QuizInput name="phone" onChange={this.InputHandler} required/>
                                
                                <SendButton><i class="far fa-check-square"></i> Отправить</SendButton>
                            </form>
                            <div>
                                <LegalLink href={myJson.JsonQuiz.endform.legallink} target="_blank">Политика приватности</LegalLink>
                            </div>
                        </K100Count>
                    ) : null}
                </WithJson>
                </RowForm>
            );
        }
        else{
            FormHandler = (
                <p>Ваша заявка отправлена. Наш менеджер свяжется с вами в ближайшее время.</p>
            );
        }

        return (
            <WithJson>
                {myJson => myJson && myJson.JsonQuiz ? (
                <QuizBlock>
                    <DLeftColMain>
                        <LeftRowForm>
                            <MainImage src={myJson.JsonQuiz.endform.endimage}/>
                        </LeftRowForm>
                    </DLeftColMain>
                    <DRightCol>
                        {FormHandler}
                    </DRightCol>
                </QuizBlock>
            ) : null}
        </WithJson>
        );
    }
}