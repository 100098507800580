import React from 'react';
import ym from 'react-yandex-metrika';
import util from 'util';

import {
    QuizBlock, QuizTitle, SpanTitleIconColor, HR, DLeftCol, AnswerColumn, RowAnswer, AnswerCard,
    AnswerImage, AnswerText, DRightCol, AssistantRow, AssistantLCol,
    AssistantImg, AssistantRCol, AssistantTitle, AssistantSubtitle, AssistantMessage, SaleCount,
} from './answers/stylesheets';

import { inject } from 'effector-react-inject';
import {createStoreConsumer} from 'effector-react'
import { JsonQuiz } from "./../components/store";
const WithJson = createStoreConsumer({JsonQuiz});

class QuestGenerator extends React.Component{
    constructor(props){
        super(props);
        this.SelectAnswer = this.SelectAnswer.bind(this);
    }
    
    SelectAnswer(myAnswer){
        this.props.ChangeQuest(myAnswer);
    }

    render(){
       return (
            <WithJson>
                {myJson => myJson && myJson.JsonQuiz ? (
                    <DLeftCol>
                        <QuizTitle>
                             <SpanTitleIconColor><i class="fas fa-tasks"></i></SpanTitleIconColor> {myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].quest}
                        </QuizTitle>
                        
                        <HR></HR>
                        <RowAnswer>
                            <AnswerColumn onClick={() => {this.SelectAnswer(myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer1) }}>
                                <AnswerCard>
                                    {myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].image1 ? <AnswerImage src={myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].image1} /> : null }
                                    <AnswerText>{myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer1}</AnswerText>
                                </AnswerCard>
                            </AnswerColumn>

                            <AnswerColumn onClick={() => {this.SelectAnswer(myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer2)}}>
                                <AnswerCard>
                                    {myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].image2 ? <AnswerImage src={myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].image2} /> : null }
                                    <AnswerText>{myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer2}</AnswerText>
                                </AnswerCard>
                            </AnswerColumn>

                            {myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer3 ? (
                            <AnswerColumn onClick={() => {this.SelectAnswer(myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer3)}}>
                                <AnswerCard>
                                    {myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].image3 ?
                                    <AnswerImage src={myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].image3} /> : null }
                                    <AnswerText>{myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer3}</AnswerText>
                                </AnswerCard>
                            </AnswerColumn>) : null}

                            {myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer4 ? (
                            <AnswerColumn onClick={() => {this.SelectAnswer(myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer4)}}>
                                <AnswerCard>
                                    {myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].image4 ?
                                    <AnswerImage src={myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].image4} /> : null }
                                    <AnswerText>{myJson.JsonQuiz.questarray['quest' + this.props.QuestNumber].answer4}</AnswerText>
                                </AnswerCard>
                            </AnswerColumn>) : null}
                        </RowAnswer>
                    </DLeftCol>
                ) : null}
            </WithJson>
        );
    }
}

export default QuestGenerator;